<template>
  <div :style="'filter: blur(4px);'">
    <b-tabs
      pills
    >
      <b-tab
        title="Inadimplência po Agência"
        active
      />
      <b-tab
        title="Arrecadação por Faturamento"
      />
    </b-tabs>
    <b-modal
      id="modalOption"
      ref="modalOptionRef"
      title="Acesso aos painéis"
      centered
      size="md"
      no-close-on-backdrop
      hide-footer
      @close.prevent="closeModal"
    >
      <!--hide-header-close-->
      <b-row class="mt-1">
        <b-col
          md="6"
          xl="6"
        >
          <b-card
            class="text-center v-card-panel"
            :style="'border-block-end: 2px solid var(--success, #ffd8b2);opacity: 1'"
            @click="openPanelGabinete"
          >
            <b-card-text>
              <div class="align-items-center gap-3">
                <b-avatar
                  rounded
                  variant="light-success"
                  size="38"
                >
                  <feather-icon
                    icon="FileTextIcon"
                    size="21"
                  />
                </b-avatar>
                <h4 class="h5 mt-1">
                  GABINETE
                </h4>
              </div>
            </b-card-text>
          </b-card>
        </b-col>
        <b-col
          md="6"
          xl="6"
        >
          <b-card
            class="text-center v-card-panel"
            :style="'border-block-end: 2px solid var(--warning, #ffd8b2);opacity: 1'"
            @click="openPanelDaf"
          >
            <b-card-text>
              <div class="align-items-center gap-3">
                <b-avatar
                  rounded
                  variant="light-warning"
                  size="38"
                >
                  <feather-icon
                    icon="DollarSignIcon"
                    size="21"
                  />
                </b-avatar>
                <h4 class="h5 mt-1">
                  DAF
                </h4>
              </div>
            </b-card-text>
          </b-card>
        </b-col>
        <b-col
          md="6"
          xl="6"
        >
          <b-card
            class="text-center v-card-panel"
            :style="'border-block-end: 2px solid var(--primary, #007bff);opacity: 1'"
            @click="openPanelComercial"
          >
            <b-card-text>
              <div class="align-items-center gap-3">
                <b-avatar
                  rounded
                  variant="light-primary"
                  size="38"
                >
                  <feather-icon
                    icon="TrendingUpIcon"
                    size="21"
                  />
                </b-avatar>
                <h4 class="h5 mt-1">
                  SUP. COMERCIAL
                </h4>
              </div>
            </b-card-text>
          </b-card>
        </b-col>
        <b-col
          md="6"
          xl="6"
        >
          <b-card
            class="text-center v-card-panel"
            :style="'border-block-end: 2px solid var(--danger, #ffd8b2);opacity: 1'"
            @click="openPanelDiop"
          >
            <b-card-text>
              <div class="align-items-center gap-3">
                <b-avatar
                  rounded
                  variant="light-danger"
                  size="38"
                >
                  <feather-icon
                    icon="SettingsIcon"
                    size="21"
                  />
                </b-avatar>
                <h4 class="h5 mt-1">
                  DIOP
                </h4>
              </div>
            </b-card-text>
          </b-card>
        </b-col>
	<b-col
	  md="6"
	  xl="6"
	>
	  <b-card
	    class="text-center v-card-panel"
	    :style="'border-block-end: 2px solid #4b4b4b;opacity: 1'"
	    @click="openPanelProjects"
	  >
	    <b-card-text>
	      <div class="align-items-center gap-3">
	        <b-avatar
	          rounded
	          variant="light-dark"
	          size="38"
	        >
	          <feather-icon
	            icon="LayersIcon"
	            size="21"
	          />
	        </b-avatar>
	        <h4 class="h5 mt-1">
	          PROJETOS
	        </h4>
	      </div>
	    </b-card-text>
	  </b-card>
	</b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import router from '@/router'

export default {
  data() {
    return {
      isHover: true,
    }
  },
  mounted() {
    this.$refs.modalOptionRef.show()
  },
  methods: {
    openPanelComercial() {
      router.push({ name: 'panels-comercial' })
    },
    openPanelGabinete() {
      router.push({ name: 'panels-gabinete' })
    },
    openPanelDaf() {
      router.push({ name: 'panels-daf' })
    },
    openPanelDiop() {
      router.push({ name: 'panels-diop' })
    },
    openPanelProjects() {
      router.push({ name: 'panels-projects' })
    },
    closeModal() {
      router.push({ name: 'home' })
    },
  },
}

</script>
<style>
    .v-card-panel {
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        cursor: pointer;
    }
    .v-card-panel:hover {
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        transition: all .1s ease-out;
    }
</style>
